<template>
  <footer class="footer footer-black footer-white">
    <div class="container-fluid">
      <div class="row">
        <nav class="footer-nav">
          <ul>
            <li>
              <a href="https://www.creative-tim.com" target="_blank" rel="noopener">Creative Tim</a>
            </li>
            <li>
              <a href="http://blog.creative-tim.com/" target="_blank" rel="noopener">Blog</a>
            </li>
            <li>
              <a href="https://www.creative-tim.com/license" target="_blank" rel="noopener">Licenses</a>
            </li>
          </ul>
        </nav>
        <div class="credits ml-auto">
          <div class="copyright">
            &copy; Coded with
            <i class="fa fa-heart heart"></i> by <a href="https://www.creative-tim.com/?ref=pdf-vuejs" target="_blank" rel="noopener">Creative Tim</a>.
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
  export default {
    name: 'main-footer'
  }
</script>
<style>
</style>
