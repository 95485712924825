import DashboardLayout from '../components/Dashboard/Layout/DashboardLayout.vue'
// GeneralViews
import NotFound from '../components/GeneralViews/NotFoundPage.vue'
// Dashboard pages
const LandingMap = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Dashboard/LandingMap.vue')

const routes = [
  {
    path: '/',
    component: DashboardLayout,
    redirect: '/map/',
    children: [
      {
          path: 'map/',
          name: 'LandingNoMap',
          component: LandingMap,
          children: [
              {
                  path: ':map_orchard',
                  name: 'MapOrchard',
                  children: [
                      {
                          path: ':map_scan',
                          name: 'MapOrchardScan',
                          children: [
                              {
                                  path: ':map_block',
                                  name: 'MapOrchardScanBlock',
                                  children: [
                                      {
                                          path: ':map_row',
                                          name: 'MapOrchardScanBlockRow',
                                          children: [
                                              {
                                                  path: ':map_bay',
                                                  name: 'MapOrchardScanBlockRowBay',
                                              }
                                          ]
                                      }
                                  ]
                              }
                          ]
                      }
                  ]
              }
          ]
      }
    ]
  },
  {path: '*', component: NotFound}
];

export default routes
